<template>
  <div id="app">
    <FixedIncome msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import FixedIncome from "./components/FixedIncome.vue";

export default {
  name: "App",
  components: {
    FixedIncome
  }
};
</script>

<style lang="scss">
#app {
  background: #fd4f47 url(./assets/top_bg.png) no-repeat;
  background-size: 750px 730px;
}
</style>
